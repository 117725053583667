<template>
  <v-row
    class="edmonton-symptoms mt-1"
    v-if="questionnaire.answers && questionnaire.answers.length > 0"
  >
    <v-col class="pt-0" cols="12">
      <div class="h2 mb-5 pl-1 title">Edmonton {{ $t("symptoms") }}</div>
    </v-col>
    <v-col cols="12" class="pb-0">
      <v-card class="d-flex pb-0 mb-1">
        <div class="w-100">
          <p class="reporting-date float-right">
            {{ $t("reported_on") }}
            {{ reportedDate }}
          </p>
        </div>
        <div class="progress-bars w-100">
          <edmonton-symptoms-progress-bars :symptoms="questionnaire.answers" />
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import EdmontonSymptomsProgressBars from "@/components/views/dashboard/members/id/partial/EdmontonSymptomsProgressBars.vue";

export default {
  name: "edmonton-symptoms",

  props: {
    questionnaire: {
      type: Object,
      default: () => {},
    },
    reportedDate: {
      type: String,
      default: "",
    },
  },

  components: {
    EdmontonSymptomsProgressBars,
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/edmonton-symptoms.scss">
</style>