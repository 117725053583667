<template>
  <v-col cols="12" class="d-flex justify-center dots-container">
    <div class="d-flex">
      <div
        class="dots"
        v-for="i in indices"
        :key="i"
        :style="`background:${getDotsColor(i)}`"
      ></div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "ProgressBarDots",

  props: {
    indices: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    getDotsColor: function (i) {
      if (i <= 2) {
        return "#6CC0A6";
      }
      if (i <= 4) {
        return "linear-gradient(90deg, #6CC0A6 5%, #F9C361 99%)";
      }
      if (i <= 6) {
        return "#F9C361";
      }
      if (i <= 9) {
        return "linear-gradient(90deg, #F9C361 30%, #ED7378 90%)";
      }

      return "#ED7378";
    },
  },
};
</script>